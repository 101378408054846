import React from "react"
import { getUser } from "../services/auth"

const Profile = () => (
    <>
    <h1>Your Profile</h1>
    <ul>
        <li>Name: {getUser().user.username} </li>
        <li>E-Mail: {getUser().user.email}</li>
    </ul>
    </>
)
export default Profile