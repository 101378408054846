import axios from 'axios'

export const isBrowser = () => typeof window !== "undefined"
export const getUser = () =>
    isBrowser() && window.localStorage.getItem("gatsbyUser")
     ? JSON.parse(window.localStorage.getItem("gatsbyUser"))
     : {}

const setUser = user => 
    window.localStorage.setItem("gatsbyUser", JSON.stringify(user))

export const handleLogin = ({ username, password }) => {
    console.log({
        'identifier': username,
        'password': password,
    });
    axios.post('http://localhost:1337/auth/local', {
        'identifier': username,
        'password': password,
    })
    .then(response => {
        return setUser(response.data);
    })
    .catch(error => {
        return false;
    });
}

export const isLoggedIn = () => {
    const user = getUser();
    return !!user.jwt;
}

export const logout = callback => {
    setUser({});
    callback();
}